var user    = localStorage.getItem('user')   ? JSON.parse(localStorage.getItem('user'))   : null;
var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {
    widgetcode:"a2bc764a996d4935cacc9909a13c76db4a9dd7c0d68e11f7d46ff521ca829c35", values:{},
    // ready:function(){}
    ready:function(){
        var _locale = document.querySelector("body").getAttribute("locale") || 'ar';
        var user    = localStorage.getItem('user')   ? JSON.parse(localStorage.getItem('user'))   : null;
        $zoho.salesiq.language(_locale);
        if (user) {
            $zoho.salesiq.visitor.name(user.address.name);
            $zoho.salesiq.visitor.contactnumber(user.address.phone_number);
        }
    }
};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.write("<div id='zsiqwidget'></div>");